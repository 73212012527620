// CandidateAnalysis.jsx
import React, { useState, useEffect } from 'react';
import {
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    Radar,
    ResponsiveContainer,
    Tooltip,
} from 'recharts';
import {
    FaLightbulb,
    FaUser,
    FaCode,
    FaBrain,
    FaComments,
    FaChartLine,
    FaExclamationTriangle,
    FaCheckCircle,
} from 'react-icons/fa';
import { getInterviewAnalysis } from '../../services/api';

const DemoAnalysis = ({
    interviewId = "32f03d5c-002c-4ee0-b860-778a8d26cebc",
    email = "kartik@gmail.com"
}) => {
    const [analysis, setAnalysis] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch analysis data from the backend
        const fetchData = async () => {
            try {
                const analysisData = await getInterviewAnalysis(interviewId, email);
                setAnalysis(analysisData.analysis);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchData();
    }, [interviewId, email]);

    // Helper function to safely retrieve skill data
    const getSkillData = (skillName) => {
        if (!analysis?.skills || !Array.isArray(analysis.skills)) return null;
        return analysis.skills.find((skill) => skill.skillName === skillName) || null;
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-background">
                <div className="text-xl text-gray-500">Loading analysis...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-background">
                <div className="text-xl text-red-500">
                    Error fetching analysis data.
                </div>
            </div>
        );
    }

    // Destructure analysis data with safe defaults
    const {
        score = 0,
        skills = [],
        feedback = 'No feedback available.',
        overallSummary = 'No summary available.',
        recommendation = 'No recommendations available.',
    } = analysis || {};

    return (
        <div className="min-h-screen bg-background">
            {/* Main Content */}
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                {/* Summary Cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                    {/* Technical Proficiency Card */}
                    <div className="bg-card rounded-xl p-6 border border-gray-200 hover:border-[var(--dark-color)] transition-colors duration-300">
                        <div className="flex items-center justify-between">
                            <div>
                                <div className="flex items-center space-x-2">
                                    <FaExclamationTriangle className="text-amber-500" />
                                    <p className="text-muted-foreground font-medium">
                                        Score
                                    </p>
                                </div>
                                <h2 className="text-3xl sm:text-4xl font-bold text-[var(--dark-color)] mt-3">
                                    {score !== null && score !== undefined ? `${score}%` : 'N/A'}
                                </h2>
                                <p className="text-sm text-muted-foreground mt-2">
                                    Needs significant improvement
                                </p>
                            </div>
                            <div className="w-16 h-16 bg-[var(--light-color)] rounded-xl flex items-center justify-center">
                                <FaCode className="text-[var(--dark-color)] text-2xl" />
                            </div>
                        </div>
                    </div>

                    {/* Problem Solving Card */}
                    <div className="bg-card rounded-xl p-6 border border-gray-200 hover:border-[var(--dark-color)] transition-colors duration-300">
                        <div className="flex items-center justify-between">
                            <div>
                                <div className="flex items-center space-x-2">
                                    <FaBrain className="text-green-500" />
                                    <p className="text-muted-foreground font-medium">
                                        Problem Solving
                                    </p>
                                </div>
                                <h2 className="text-3xl sm:text-4xl font-bold text-[var(--dark-color)] mt-3">
                                    {getSkillData('Problem Solving Ability')?.skillScore !== null &&
                                        getSkillData('Problem Solving Ability')?.skillScore !== undefined
                                        ? `${getSkillData('Problem Solving Ability')?.skillScore}%`
                                        : '90%'}
                                </h2>
                                <p className="text-sm text-muted-foreground mt-2">
                                    Basic analytical skills present
                                </p>
                            </div>
                            <div className="w-16 h-16 bg-[var(--light-color)] rounded-xl flex items-center justify-center">
                                <FaBrain className="text-[var(--dark-color)] text-2xl" />
                            </div>
                        </div>
                    </div>

                    {/* Communication Skills Card */}
                    <div className="bg-card rounded-xl p-6 border border-gray-200 hover:border-[var(--dark-color)] transition-colors duration-300">
                        <div className="flex items-center justify-between">
                            <div>
                                <div className="flex items-center space-x-2">
                                    <FaComments className="text-blue-500" />
                                    <p className="text-muted-foreground font-medium">
                                        Communication
                                    </p>
                                </div>
                                <h2 className="text-3xl sm:text-4xl font-bold text-[var(--dark-color)] mt-3">
                                    {getSkillData('Communication Skills')?.skillScore !== null &&
                                        getSkillData('Communication Skills')?.skillScore !== undefined
                                        ? `${getSkillData('Communication Skills')?.skillScore}%`
                                        : '73%'}
                                </h2>
                                <p className="text-sm text-muted-foreground mt-2">
                                    Strong interpersonal skills
                                </p>
                            </div>
                            <div className="w-16 h-16 bg-[var(--light-color)] rounded-xl flex items-center justify-center">
                                <FaComments className="text-[var(--dark-color)] text-2xl" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Enhanced Skills Analysis */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                    {/* Radar Chart */}
                    <div className="bg-card rounded-xl p-6 sm:p-8 border border-gray-200 hover:border-[var(--dark-color)] transition-colors duration-300">
                        <div className="flex items-center space-x-3 mb-4 sm:mb-6">
                            <FaChartLine className="text-[var(--dark-color)] text-xl" />
                            <h2 className="text-xl sm:text-2xl font-bold">Skills Distribution</h2>
                        </div>
                        {skills.length > 0 ? (
                            <div className="h-64 sm:h-80">
                                <ResponsiveContainer width="100%" height="100%">
                                    <RadarChart data={skills}>
                                        <PolarGrid stroke="var(--border-color)" />
                                        <PolarAngleAxis
                                            dataKey="skillName"
                                            tick={{
                                                fill: 'var(--foreground)',
                                                fontSize: 10,
                                                fontWeight: 500,
                                                textAnchor: 'middle',
                                            }}
                                        />
                                        <Radar
                                            name="Skills"
                                            dataKey="skillScore"
                                            stroke="var(--dark-color)"
                                            fill="var(--dark-color)"
                                            fillOpacity={0.3}
                                        />
                                        <Tooltip
                                            contentStyle={{
                                                backgroundColor: 'var(--card)',
                                                border: '1px solid var(--border-color)',
                                                borderRadius: '12px',
                                                padding: '12px',
                                            }}
                                        />
                                    </RadarChart>
                                </ResponsiveContainer>
                            </div>
                        ) : (
                            <div className="text-center text-gray-500">
                                No skills data available to display.
                            </div>
                        )}
                    </div>

                    {/* Detailed Skills Breakdown */}
                    <div className="bg-card rounded-xl p-6 sm:p-8 border border-gray-200 hover:border-[var(--dark-color)] transition-colors duration-300">
                        <div className="flex items-center space-x-3 mb-4 sm:mb-6">
                            <FaBrain className="text-[var(--dark-color)] text-xl" />
                            <h2 className="text-xl sm:text-2xl font-bold">Skill Analysis</h2>
                        </div>
                        {skills.length > 0 ? (
                            <div className="space-y-6">
                                {skills.map((skill, index) => (
                                    <div key={index} className="group">
                                        <div className="flex justify-between items-center mb-2 sm:mb-3">
                                            <div>
                                                <h3 className="font-semibold text-lg sm:text-xl">
                                                    {skill.skillName || 'Unnamed Skill'}
                                                </h3>
                                                <p className="text-sm text-muted-foreground mt-1">
                                                    {skill.reason || 'No reason provided.'}
                                                </p>
                                            </div>
                                            <span className="text-xl sm:text-2xl font-bold text-[var(--dark-color)]">
                                                {skill.skillScore !== null && skill.skillScore !== undefined
                                                    ? `${skill.skillScore}%`
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                        <div className="w-full bg-gray-200 rounded-full h-3">
                                            <div
                                                className="bg-gradient-to-r from-[var(--dark-color)] to-[var(--moredark-color)] h-3 rounded-full transition-all duration-500 ease-out group-hover:opacity-90"
                                                style={{
                                                    width:
                                                        skill.skillScore !== null && skill.skillScore !== undefined
                                                            ? `${skill.skillScore}%`
                                                            : '0%',
                                                }}
                                            ></div>
                                        </div>
                                        {/* Optional Improvement Section */}
                                        {/* Uncomment and adjust if 'improvement' data becomes available */}
                                        {/* 
                                        {skill.improvement && (
                                            <div className="mt-2 sm:mt-3 p-3 bg-[var(--light-color)] rounded-lg">
                                                <p className="text-sm font-medium">
                                                    <span className="text-[var(--dark-color)]">💡 Improvement Area: </span>
                                                    {skill.improvement}
                                                </p>
                                            </div>
                                        )} 
                                        */}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-center text-gray-500">
                                No skills data available for detailed analysis.
                            </div>
                        )}
                    </div>
                </div>

                {/* Enhanced Detailed Analysis */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                    {/* Key Observations */}
                    <div className="bg-card rounded-xl p-6 sm:p-8 border border-gray-200 hover:border-[var(--dark-color)] transition-colors duration-300">
                        <div className="flex items-center space-x-3 mb-4 sm:mb-6">
                            <FaCheckCircle className="text-[var(--dark-color)] text-xl" />
                            <h2 className="text-xl sm:text-2xl font-bold">Key Observations</h2>
                        </div>
                        <div className="space-y-4">
                            {/* Overall Summary */}
                            <div className="p-4 bg-gray-100 rounded-lg">
                                <h3 className="font-semibold text-lg sm:text-xl mb-2">
                                    Overall Summary
                                </h3>
                                <p className="text-sm text-muted-foreground leading-relaxed">
                                    {overallSummary || 'No summary available.'}
                                </p>
                            </div>
                            {/* Feedback */}
                            <div className="p-4 bg-gray-100 rounded-lg">
                                <h3 className="font-semibold text-lg sm:text-xl mb-2">
                                    Feedback
                                </h3>
                                <p className="text-sm text-muted-foreground leading-relaxed">
                                    {feedback || 'No feedback available.'}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Development Plan */}
                    <div className="bg-card rounded-xl p-6 sm:p-8 border border-gray-200 hover:border-[var(--dark-color)] transition-colors duration-300">
                        <div className="flex items-center space-x-3 mb-4 sm:mb-6">
                            <FaLightbulb className="text-[var(--dark-color)] text-xl" />
                            <h2 className="text-xl sm:text-2xl font-bold">Development Plan</h2>
                        </div>
                        <div className="space-y-4">
                            {/* Recommendations */}
                            <div className="p-4 border-l-4 border-[var(--dark-color)] bg-gray-100 rounded-lg">
                                <h3 className="font-semibold text-lg sm:text-xl mb-2">
                                    Recommendations
                                </h3>
                                <p className="text-sm text-muted-foreground">
                                    {recommendation || 'No recommendations available.'}
                                </p>
                            </div>
                            {/* Additional Sections can be added here */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DemoAnalysis;
