// src/utils/audioUtils.js
export const encodeWAV = (audioBuffer) => {
    const numberOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const format = 1; // PCM
    const bitDepth = 16;

    // Interleave channels if necessary
    let interleaved;
    if (numberOfChannels === 2) {
        const channelData0 = audioBuffer.getChannelData(0);
        const channelData1 = audioBuffer.getChannelData(1);
        interleaved = new Float32Array(channelData0.length + channelData1.length);
        for (let i = 0; i < channelData0.length; i++) {
            interleaved[i * 2] = channelData0[i];
            interleaved[i * 2 + 1] = channelData1[i];
        }
    } else {
        interleaved = audioBuffer.getChannelData(0);
    }

    // Convert float samples to 16-bit PCM
    const buffer = new ArrayBuffer(44 + interleaved.length * 2);
    const view = new DataView(buffer);

    /* RIFF identifier */
    writeString(view, 0, 'RIFF');
    /* file length */
    view.setUint32(4, 36 + interleaved.length * 2, true);
    /* RIFF type */
    writeString(view, 8, 'WAVE');
    /* format chunk identifier */
    writeString(view, 12, 'fmt ');
    /* format chunk length */
    view.setUint32(16, 16, true);
    /* sample format (raw) */
    view.setUint16(20, format, true);
    /* channel count */
    view.setUint16(22, numberOfChannels, true);
    /* sample rate */
    view.setUint32(24, sampleRate, true);
    /* byte rate (sample rate * block align) */
    view.setUint32(28, sampleRate * numberOfChannels * bitDepth / 8, true);
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, numberOfChannels * bitDepth / 8, true);
    /* bits per sample */
    view.setUint16(34, bitDepth, true);
    /* data chunk identifier */
    writeString(view, 36, 'data');
    /* data chunk length */
    view.setUint32(40, interleaved.length * 2, true);

    // Write PCM samples
    let offset = 44;
    for (let i = 0; i < interleaved.length; i++, offset += 2) {
        let s = Math.max(-1, Math.min(1, interleaved[i]));
        s = s < 0 ? s * 0x8000 : s * 0x7FFF;
        view.setInt16(offset, s, true);
    }

    return new Blob([view], { type: 'audio/wav' });
};

const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
    }
};


export const parseTimeToSeconds = (timeStr) => {
    if (!timeStr) return 0;
    const [hours, minutes, secondsWithMs] = timeStr.split(":");
    if (!hours || !minutes || !secondsWithMs) return 0;
    const [seconds, ms] = secondsWithMs.split(".");
    return (
        parseInt(hours, 10) * 3600 +
        parseInt(minutes, 10) * 60 +
        parseInt(seconds, 10) +
        parseInt(ms || "0", 10) / 1000
    );
};

export const decodeAudio = async (context, audioChunk) => {
    const int16Data = new Int16Array(audioChunk.buffer);

    // Convert Int16 samples to Float32 samples
    const float32Data = new Float32Array(int16Data.length);
    for (let i = 0; i < int16Data.length; i++) {
        float32Data[i] = int16Data[i] / 32768; // Normalize to [-1, 1]
    }

    // Create an AudioBuffer with the correct sample rate
    const audioBuffer = context.createBuffer(
        1, // number of channels (mono)
        float32Data.length,
        24000 // Original sample rate of the audio data
    );

    // Populate the AudioBuffer with the Float32 samples
    audioBuffer.getChannelData(0).set(float32Data);

    return audioBuffer;
};

export const base64ToUint8Array = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
};

export const mergeAudioBuffers = (buffers) => {
    if (!buffers.length) return null;

    const numberOfChannels = 1; // Assuming mono audio
    const sampleRate = 24000; // Consistent sample rate

    // Calculate total length
    const totalLength = buffers.reduce((sum, buffer) => sum + buffer.length, 0);

    // Create a new buffer
    const mergedBuffer = new AudioBuffer({
        length: totalLength,
        numberOfChannels: numberOfChannels,
        sampleRate: sampleRate,
    });

    // Copy each buffer into the merged buffer
    let offset = 0;
    buffers.forEach((buffer) => {
        mergedBuffer.copyToChannel(buffer.getChannelData(0), 0, offset);
        offset += buffer.length;
    });

    return mergedBuffer;
};

export const createSilenceBuffer = (duration) => {
    const sampleRate = 24000; // Use the original sample rate
    const frameCount = Math.floor(sampleRate * duration);
    const silenceBuffer = new AudioBuffer({
        length: frameCount,
        numberOfChannels: 1,
        sampleRate: sampleRate,
    });
    // Silence is represented by zeros; no need to fill explicitly
    return silenceBuffer;
};