// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InterviewInterface from './components/InterviewPage/InterviewPage';
import InterviewStartPage from './components/StartPage/StartPage';
import StartPage from './components/StartPage/EmailValidation';
import ThankYouPage from './components/EndPage/Thankyou';
import ConversationDisplay from './components/InterviewPage/Conversations';
import InterviewAnalysis from './components/InterviewPage/InterviewAnalysis';
import EmailAnalysisPage from './components/InterviewPage/InterviewList';
import CodingInterviewInterface from './components/InterviewPage/CodingInterviewPage';
import MediaPlayer from './Testing';
import './App.css'
import ReportPage from './components/InterviewPage/InterviewReport';
import DemoVideo from './components/Demo/DemoVideo';
import AIInterviewDemo from './components/Demo/DemoPage';
import ContactPage from './components/ContactUs/ContactUs';
// We'll create this component next

function App() {
    return (
        <Router>
            <Routes>
{/* 
                <Route path="/interview/" element={<InterviewInterface />} />
                <Route path="/interview/coding/" element={<CodingInterviewInterface />} />
                <Route path="/" element={<StartPage />} />
                <Route path="/thankyou" element={<ThankYouPage />} />
                <Route path="/interview/analysis" element={<ReportPage />} />
                <Route path="/interview/emails" element={<EmailAnalysisPage />} />
                <Route path="/testing" element={<MediaPlayer />} />
                <Route path="/demo/video" element={<DemoVideo />} /> */}
                <Route path="/demo" element={<AIInterviewDemo />} />
               
            </Routes>
        </Router>
    );
}

export default App;
